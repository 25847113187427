import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import { Collection } from '@kaiber/shared-types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useState, memo, useCallback, useMemo, useRef } from 'react'
import { InView } from 'react-intersection-observer'

import { LibraryEmptyState } from './LibraryEmptyState'
import { LibraryHeader } from './LibraryHeader'
import { MEDIA_GRID_MAX_WIDTH } from '../../../constants'
import { useThemeContext } from '../../../context'
import {
  useListMedia,
  useInitialLoad,
  useHorizontalResizer,
  useMutateCollection,
  useQueryCollection,
} from '../../../hooks'
import { CollectionService } from '../../../services/CollectionService'
import { myLibraryStore } from '../../../stores'
import {
  ALL_COLLECTION_IDS_QUERY_KEY,
  collectionUtils,
} from '../../../utils/collectionUtils'
import { reloadTagFilters } from '../../../utils/mediaTagUtils'
import { CollectionDisplay } from '../Collection/CollectionDisplay'
import { CollectionsPreview } from '../Collection/CollectionsPreview'
import { MediaGrid } from '../Media'
import { TagFilter, LibraryViewMode } from '@/types'

export const MyLibrary = memo(() => {
  const queryClient = useQueryClient()
  const tagFilters = myLibraryStore.useTagFilters()
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] = useState(false)
  const [isCollectionsOptionsOpen, setIsCollectionsOptionsOpen] =
    useState(false)
  const [selectedCollectionId, setSelectedCollectionId] = useState<string>()
  const { data: selectedCollection } = useQueryCollection(selectedCollectionId)
  const viewMode = selectedCollectionId
    ? LibraryViewMode.Collection
    : LibraryViewMode.Overview
  const containerRef = useRef<HTMLDivElement>(null)

  const { width } = useHorizontalResizer({
    containerRef,
    minWidth: MEDIA_GRID_MAX_WIDTH,
    maxWidth: MEDIA_GRID_MAX_WIDTH * 4,
    initialWidth: MEDIA_GRID_MAX_WIDTH,
    useFadeBar: true,
  })

  const { colors } = useThemeContext()

  const columns = useMemo(
    () => Math.max(1, Math.floor(width / MEDIA_GRID_MAX_WIDTH)),
    [width],
  )
  const isExpanded = useMemo(() => columns >= 2, [columns])

  const { deleteCollection } = useMutateCollection()

  const toggleCollectionsOptions = () => {
    setIsCollectionsOptionsOpen(!isCollectionsOptionsOpen)
  }

  const activeTagFilters = tagFilters?.filter((tag) => tag.selected)

  const {
    error: mediaError,
    fetchNextPage,
    isFetching,
    status,
  } = useListMedia(activeTagFilters)

  const {
    data: collectionIds,
    error: collectionsError,
    isPending: isCollectionsPending,
  } = useQuery({
    queryKey: ALL_COLLECTION_IDS_QUERY_KEY,
    queryFn: async () => {
      const collections = await CollectionService.listCollections()

      collections.forEach((collection: Collection) => {
        queryClient.setQueryData(
          collectionUtils.keyOf(collection.collectionId),
          collection,
        )
      })

      return collections.map((c) => c.collectionId)
    },
  })

  useInitialLoad(reloadTagFilters)

  const handleFilterToggle = useCallback(
    async (tag: TagFilter) => {
      const newFilter = tagFilters.map((t) => {
        const isSelectedNew = !tag.selected
        if (t.ns === tag.ns && t.name === tag.name) {
          return { ...t, selected: isSelectedNew }
        }
        if (t.ns === tag.ns && isSelectedNew) {
          return { ...t, selected: false }
        }
        return t
      })
      myLibraryStore.setTagFilters(newFilter)
    },
    [tagFilters],
  )

  const handleCollectionSelect = useCallback((collectionId: string) => {
    setSelectedCollectionId(collectionId)
  }, [])

  const handleBackToOverview = useCallback(() => {
    setSelectedCollectionId(null)
  }, [])

  const handleDeleteCollection = useCallback(
    (collectionId: string) => {
      deleteCollection(collectionId)
      handleBackToOverview()
    },
    [deleteCollection, handleBackToOverview],
  )

  // TODO(ENG-2680): Handler for duplicating a collection
  // const handleDuplicateCollection = useCallback((collectionId: string) => {
  //   console.log(
  //     `Duplication for collection ${collectionId} not yet implemented`,
  //   )
  // }, [])

  const mediaIds = myLibraryStore.useMediaIds()

  const handleLibraryDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()
    },
    [],
  )

  return (
    <div
      ref={containerRef}
      className={`fixed top-24 right-4 z-20 p-4 pt-0 ${colors.background.neutral} rounded-2xl flex flex-col h-[calc(100vh-360px)] border border-gray-400`}
      onDrop={handleLibraryDrop}
    >
      <LibraryHeader
        tagFilters={tagFilters}
        handleFilterToggle={handleFilterToggle}
        isExpanded={isExpanded}
        isFilterDropdownOpen={isFilterDropdownOpen}
        setIsFilterDropdownOpen={setIsFilterDropdownOpen}
        viewMode={viewMode}
        onBackToOverview={handleBackToOverview}
      />
      {viewMode === LibraryViewMode.Overview ? (
        <>
          <div className={colors.text.default}>Collections</div>
          {isCollectionsPending ? (
            <div className={colors.text.default}>Loading collections...</div>
          ) : collectionsError ? (
            <div className={colors.text.default}>
              Error loading collections: {collectionsError.message}
            </div>
          ) : (
            <div onDrop={(e) => e.stopPropagation()}>
              <CollectionsPreview
                collectionIds={collectionIds || []}
                onCollectionClick={handleCollectionSelect}
              />
            </div>
          )}
          <div className={colors.text.default}>Media</div>
          <div className='flex-1 mt-2 overflow-y-auto'>
            {mediaIds.length > 0 ? (
              <MediaGrid
                mediaIds={mediaIds}
                columns={columns}
                isLoading={isFetching}
              />
            ) : (
              <LibraryEmptyState
                tagFilters={tagFilters}
                setIsFilterDropdownOpen={setIsFilterDropdownOpen}
              />
            )}
            {status === 'error' && <p>Fetch error: {mediaError.message}</p>}
            <InView
              className={'h-1'}
              onChange={() => !isFetching && fetchNextPage()}
            ></InView>
          </div>
        </>
      ) : (
        <>
          <div
            className={`flex justify-between items-center ${colors.text.default} pb-4`}
          >
            <span className='truncate'>{selectedCollection.name}</span>
            <div className='relative'>
              <EllipsisHorizontalIcon
                className={`w-6 h-6 ${colors.text.default} cursor-pointer`}
                onClick={toggleCollectionsOptions}
              />
              {isCollectionsOptionsOpen && (
                <div className='absolute right-0 py-2 w-48 bg-k2-gray-600 rounded-md shadow-lg z-30'>
                  <button
                    className='block px-4 py-2 text-sm text-k2-gray-100 hover:bg-k2-gray-500 w-full text-left'
                    onClick={() =>
                      handleDeleteCollection(selectedCollection.collectionId)
                    }
                  >
                    Delete
                  </button>
                </div>
              )}
            </div>
          </div>
          <CollectionDisplay
            isPending={isCollectionsPending}
            error={collectionsError}
            collection={selectedCollection}
            columns={columns}
            width={width}
          />
        </>
      )}
    </div>
  )
})

MyLibrary.displayName = 'MyLibrary'
