import { CollectionAction, Collection } from '@kaiber/shared-types'
import { useReactFlow, XYPosition } from '@xyflow/react'
import { Node } from '@xyflow/react'
import { useCallback } from 'react'

import { useMutateCollection, useNodeUtility } from './index'
import { useAnalytics } from '../../hooks/useAnalytics'
import { NodeType, AllNodeTypes, AnalyticsEvent, NodeOrigin } from '@/types'

export const useCreateCollection = () => {
  const { getNodes, addNodes, deleteElements, updateNodeData } = useReactFlow()
  const { createCollection, updateCollection } = useMutateCollection()
  const { getMaxNodeZIndex } = useNodeUtility()
  const { trackNodeEvent } = useAnalytics()

  const createCollectionFromNodes = useCallback(
    async (nodes: Node<{ media: { mediaId: string } }>[]) => {
      if (nodes.length === 0) return

      try {
        const collectionNode: AllNodeTypes = {
          id: `collection-${Math.random()}`,
          zIndex: getMaxNodeZIndex(),
          position: nodes[0].position,
          data: { collectionId: null }, // we pass a null collectionId here to avoid querying in the collectionNode
          type: NodeType.CollectionNode,
        }
        addNodes(collectionNode)
        deleteElements({ nodes: nodes })

        const newCollection = await createCollection([])

        updateNodeData(collectionNode.id, {
          collectionId: newCollection.collectionId,
        })

        await updateCollection({
          collectionId: newCollection.collectionId,
          action: 'ADD_MEDIA' as CollectionAction,
          payload: {
            mediaIds: nodes.map((node) => node.data.media.mediaId),
            index: 0,
          },
        })

        trackNodeEvent(collectionNode, AnalyticsEvent.NodeAdded, {
          nodeOrigin:
            nodes.length > 1
              ? NodeOrigin.CreatedCollectionFromSelection
              : NodeOrigin.CreatedCollectionFromSingleNode,
        })
      } catch (error) {
        console.error('Error creating new collection:', error)
      }
    },
    [
      createCollection,
      updateCollection,
      getMaxNodeZIndex,
      addNodes,
      trackNodeEvent,
      deleteElements,
      updateNodeData,
    ],
  )

  const createCollectionFromSelection = useCallback(() => {
    const selectedNodes = getNodes().filter(
      (node): node is Node<{ media: { mediaId: string } }> =>
        node.selected && node.type === NodeType.MediaNode,
    )
    return createCollectionFromNodes(selectedNodes)
  }, [getNodes, createCollectionFromNodes])

  const createCollectionFromSingleNode = useCallback(
    (nodeId: string) => {
      const node = getNodes().find(
        (n): n is Node<{ media: { mediaId: string } }> =>
          n.id === nodeId && n.type === NodeType.MediaNode,
      )
      return node ? createCollectionFromNodes([node]) : undefined
    },
    [getNodes, createCollectionFromNodes],
  )

  const addCollectionToCanvas = useCallback(
    (collection: Collection, position: XYPosition) => {
      const collectionNode: AllNodeTypes = {
        id: `collection-${Math.random()}`,
        zIndex: getMaxNodeZIndex(),
        position,
        data: { collectionId: collection.collectionId },
        type: NodeType.CollectionNode,
      }
      addNodes(collectionNode)
    },
    [getMaxNodeZIndex, addNodes],
  )

  return {
    createCollectionFromSelection,
    createCollectionFromSingleNode,
    addCollectionToCanvas,
  }
}
