import { GetMediaRequest } from '@kaiber/shared-types'
import axios, { AxiosProgressEvent } from 'axios'
import { toast } from 'react-toastify'

import { API_ENDPOINTS, HEADERS, VIDEO_UPLOAD } from '../constants'
import { getPrompt } from '../utils'
import { getProxiedR2FileUrl, initializeFileFromBlob } from '../utils/fileUtils'
import { normalizeTagName } from '../utils/mediaTagUtils'
import { getFormattedInitialScene } from '../utils/sceneUtils'
import {
  ModelType,
  VideoForm,
  ImageForm,
  TagFilter,
  Flow,
  Media,
  Metadata,
  TagNamespace,
} from '@/types'

/**
 * Generates images and videos. Note: Currently generates images only.
 */
export const processMedia = async (flow: Flow) => {
  const { formValues } = flow

  const payload = {
    imageArgs: {
      ...formValues,
      curatedStyle: formValues.style,
      batchSize: 1,
      isPreview: false,
    },
    flow,
  }

  try {
    const response = await axios.post(API_ENDPOINTS.PROCESS_MEDIA, payload, {
      headers: HEADERS,
    })

    return response
  } catch (err: any) {
    toast.error('Failed to generate an image/video. Please try again.')
    console.error(
      'Failed to generate an image/video. Please try again. Error:',
      err,
      'Response:',
      err.response,
    )
  }
}

export const generateInpaintImage = async (flow: Flow) => {
  const { formValues } = flow
  const { userProvidedStartingFrame, maskPrompt, subject, style } =
    formValues as ImageForm

  const payload = {
    inpaintImageArgs: {
      initImg: userProvidedStartingFrame,
      maskPrompt,
      inpaintPrompt: getPrompt(subject, style),
    },
    flow,
  }

  try {
    const response = await axios.post(API_ENDPOINTS.PROCESS_MEDIA, payload, {
      headers: HEADERS,
    })

    return response
  } catch (err: any) {
    toast.error('Failed to generate an image/video. Please try again.')
    console.error(
      'Failed to generate an image/video. Please try again. Error:',
      err,
      'Response:',
      err.response,
    )
  }
}

export const generateUpscaleV1Image = async (flow: Flow) => {
  const { formValues } = flow
  const { userProvidedStartingFrame } = formValues as ImageForm

  if (!userProvidedStartingFrame) {
    throw new Error('No image provided for upscaling')
  }

  const payload = {
    flow,
    upscaleV1Args: {
      ...formValues,
    },
  }

  try {
    const response = await axios.post(API_ENDPOINTS.PROCESS_MEDIA, payload, {
      headers: HEADERS,
    })

    return response
  } catch (err: any) {
    if (err.response) {
      toast.error(
        err.response.data?.message ||
          err.response.data?.error ||
          err.response.data,
      )
    } else {
      toast.error(err.message)
    }
    console.error(
      'Failed to generate an upscaled image. Please try again. Error:',
      err,
      'Response:',
      err.response,
    )
    throw err
  }
}

export const generateMotionV3 = async (flow: Flow) => {
  const { formValues } = flow
  const { style, subject } = formValues as VideoForm

  const prompt = getPrompt(subject, style)
  const payload = {
    flow,
    motionV3Args: {
      ...formValues,
      prompt,
      scenes: getFormattedInitialScene(
        ModelType.MotionV3,
        formValues as VideoForm,
      ),
      retainInitialImage: false, // TODO figure out why we need this if at all
    },
  }

  return await axios.post(API_ENDPOINTS.PROCESS_MEDIA, payload, {
    headers: HEADERS,
  })
}

export const generateTransformV3 = async (flow: Flow) => {
  const { formValues } = flow
  const { style, subject } = formValues as VideoForm

  const payload = {
    flow,
    transformV3Args: {
      ...formValues,
      prompt: getPrompt(subject, style),
    },
    seed: Math.floor(Math.random() * 1000000),
  }

  try {
    const response = await axios.post(API_ENDPOINTS.PROCESS_MEDIA, payload, {
      headers: HEADERS,
    })

    return response
  } catch (err: any) {
    if (err.response) {
      toast.error(
        err.response.data?.message ||
          err.response.data?.error ||
          err.response.data,
      )
    } else {
      toast.error(err.message)
    }
    throw err
  }
}

export const generateFlipbookV1 = async (flow: Flow) => {
  const { formValues } = flow
  const {
    aspectRatio,
    style,
    subject,
    userProvidedStartingFrame,
    height,
    width,
  } = formValues
  const prompt = getPrompt(subject, style)

  const payload = {
    flow,
    flipbookV1Args: {
      prompt,
      aspectRatio,
      scenes: getFormattedInitialScene(
        ModelType.FlipbookV1,
        formValues as VideoForm,
      ),
      userProvidedStartingFrame,
      height,
      width,
    },
  }

  return await axios.post(API_ENDPOINTS.PROCESS_MEDIA, payload, {
    headers: HEADERS,
  })
}

export const getVideo = async (videoId: string) => {
  const response = await axios.get(`${API_ENDPOINTS.GET_VIDEO}/${videoId}`, {
    headers: HEADERS,
  })

  return response
}

export const getMedia = async ({
  mediaId,
}: GetMediaRequest): Promise<Media | null> => {
  const response = await axios.get(`${API_ENDPOINTS.GET_MEDIA}/${mediaId}`, {
    headers: HEADERS,
  })
  if (response.data && response.data.media) {
    return response.data.media
  }
}

/**
 * Retrieve data to hydrate the My Materials component
 * TODO update with types from server
 */
export const getMyMedia = async (
  perPage: number,
  nextCursor?: string,
  tags?: TagFilter[],
): Promise<any> => {
  const params = {
    perPage,
    ...(nextCursor && { nextCursor }),
    ...(tags?.length > 0 && { tags: JSON.stringify(tags) }),
  }

  const response = await axios.get(API_ENDPOINTS.GET_MY_MEDIA, {
    headers: HEADERS,
    params,
  })

  return response.data
}
/**
 * Retrieve data to hydrate My Materials filter-by-tag component.
 */
export const listUniqueTags = async (): Promise<TagFilter[]> => {
  const response = await axios.get(API_ENDPOINTS.LIST_UNIQUE_TAGS)
  return response.data
}

/**
 * Saves a media file to the server.
 */
export const saveMedia = async (
  file: File,
  tags: TagFilter[],
  metadata?: Metadata,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  abortSignal?: AbortSignal,
): Promise<Media> => {
  const formData = new FormData()
  formData.append('media', file)
  formData.append('tags', JSON.stringify(tags))

  if (metadata) {
    Object.entries(metadata).forEach(([key, value]) => {
      formData.append(key, value.toString())
    })
  }

  try {
    const response = await axios.post(API_ENDPOINTS.SAVE_MEDIA, formData, {
      headers: {
        ...HEADERS,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
      signal: abortSignal,
    })

    return response.data.media
  } catch (err: any) {
    if (err.response) {
      toast.error(
        err.response.data?.message ||
          err.response.data?.error ||
          err.response.data,
      )
    } else {
      toast.error(err.message)
    }
    throw err // rethrowing; otherwise caller needs to deal with missing return value in catch block
  }
}

/**
 * Fetches a file from a URL and saves it to the server.
 */
export const saveMediaFromUrl = async (url: string): Promise<Media> => {
  try {
    const response = await fetch(url)

    if (!response.ok) {
      throw new Error('Failed to fetch file from URL')
    }

    const validContentTypes = ['image', 'video', 'audio']
    const mediaType = validContentTypes.find((validType) =>
      response.headers.get('Content-Type').startsWith(validType),
    )
    if (!mediaType) {
      throw new Error(
        'URL does not point to a valid image, video, or audio file',
      )
    }

    const blob = await response.blob()
    const file = await initializeFileFromBlob(blob)

    return await saveMedia(file, [
      { ns: TagNamespace.MediaType, name: normalizeTagName(mediaType) },
      { ns: TagNamespace.Uploaded, name: 'Yes' },
    ])
  } catch (err: any) {
    toast.error(err.message || 'Failed to fetch file from URL')
    throw err
  }
}

/**
 * Uploads asset and returns the s3 key.
 *
 * Note: this is a temporary solution for video uploads.
 *       we want to use saveMedia moving forward.
 */
export const uploadAsset = async (file: File) => {
  const data = new FormData()
  data.append(VIDEO_UPLOAD, file)

  const response = await axios.post(API_ENDPOINTS.UPLOAD_ASSET, data, {
    headers: HEADERS,
  })

  return response.data.init_video_s3_key
}

/**
 * Get a signed url for asset given an assetKey.
 *
 * Note: asset urls in R2 expire after 24 hours, so they need to be dynamically
 *       fetched with an asset key. Also to avoid CORS issues, we proxy the R2
 *       urls through our server.
 *
 * @param assetKey string
 * @returns string
 */
export const getSignedUrl = async (assetKey: string) => {
  try {
    const response = await axios.get(API_ENDPOINTS.GET_SIGNED_URL, {
      params: {
        asset: assetKey,
      },
    })
    return getProxiedR2FileUrl(response.data.signedUrl)
  } catch (error) {
    throw new Error('Failed to fetch asset')
  }
}

/**
 *  Fetch new announcements
 **/

export const fetchAnnouncement = async (
  announcementsLastViewed: string,
  announcementType: string,
) => {
  try {
    const response = await axios.post('/api/get_updates_announcement', {
      announcementsLastViewed,
      announcementType,
    })

    return response
  } catch (error) {
    throw new Error('Failed to fetch announcements')
  }
}

export const generateFluxImage = async (flow: Flow) => {
  const { formValues } = flow
  const { subject } = formValues

  const payload = {
    flow,
    fluxImageArgs: {
      ...formValues,
      prompt: subject,
      seed: -1,
    },
  }

  try {
    const response = await axios.post(API_ENDPOINTS.PROCESS_MEDIA, payload, {
      headers: HEADERS,
    })

    return response
  } catch (err: any) {
    if (err.response) {
      toast.error(
        err.response.data?.message ||
          err.response.data?.error ||
          err.response.data,
      )
    } else {
      toast.error(err.message)
    }
    throw err
  }
}

export const fetchSubjectPrompts = async () => {
  const response = await axios.get(API_ENDPOINTS.GET_DEFAULT_PROMPTS, {
    headers: HEADERS,
  })

  return response
}
