import { Collection } from '@kaiber/shared-types'
import { useRef, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { createPortal } from 'react-dom'

import { CollectionPreviewFrame } from './CollectionPreviewFrame'
import { useCanvasContext, useThemeContext } from '../../../context'
import {
  useHorizontalScroll,
  useMousePosition,
  useMutateCollection,
  useNodeUtility,
} from '../../../hooks'
import { useQueryCollections } from '../../../hooks/k2/useQueryCollection'
import { AddCollectionIcon } from '../../../images/icons/AddCollectionIcon'
import { UploadIcon } from '../../../images/icons/UploadIcon'
import { DropType } from '../../../types/k2'
import { cn } from '../../../utils'
import { dispatchDropEvent } from '../../../utils/dndUtils'

interface CollectionsPreviewProps {
  collectionIds: string[]
  onCollectionClick: (collectionId: string) => void
}

export const CollectionsPreview = ({
  collectionIds,
  onCollectionClick,
}: CollectionsPreviewProps) => {
  const { colors } = useThemeContext()
  const scrollRef = useRef<HTMLDivElement>(null)
  useHorizontalScroll(scrollRef)
  const { createCollection, prependMedia } = useMutateCollection()
  const { getReactFlowElement } = useCanvasContext()
  const { positionRef: mousePositionRef } = useMousePosition()
  const { getMaxNodeZIndex } = useNodeUtility()
  const [isCreateButtonDraggedOver, setIsCreateButtonDraggedOver] =
    useState(false)

  const collectionQueries = useQueryCollections(collectionIds)
  const collections = collectionQueries
    .filter((query) => query.isSuccess)
    .map((query) => query.data as Collection)

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      const draggedCollection = collections.find(
        (c) => c.collectionId === result.draggableId,
      )
      if (draggedCollection) {
        dispatchDropEvent(getReactFlowElement(), mousePositionRef.current, {
          type: DropType.Collection,
          [DropType.Collection]: JSON.stringify(draggedCollection),
        })
      }
    } else if (result.destination) {
      // Handle reordering logic here if needed in the future
    }
  }

  const handleCreateButtonDragOver = (
    event: React.DragEvent<HTMLDivElement>,
  ) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'copy'
    setIsCreateButtonDraggedOver(true)
  }

  const handleCreateButtonDragLeave = () => {
    setIsCreateButtonDraggedOver(false)
  }

  const handleDrop = async (
    event: React.DragEvent<HTMLDivElement>,
    collectionId?: string,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    setIsCreateButtonDraggedOver(false)
    const droppedMediaId = event.dataTransfer.getData('text/plain')

    if (droppedMediaId) {
      if (collectionId) {
        // Drop on existing collection
        await prependMedia(collectionId, droppedMediaId)
      } else {
        // Drop on "create collection" icon
        await createCollection([droppedMediaId])
      }
    }
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div ref={scrollRef} className={`w-full overflow-x-auto p-3 pl-1`}>
        <Droppable droppableId='collections' direction='horizontal'>
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className='inline-flex space-x-3'
            >
              <div
                className={cn(
                  `flex justify-center items-center w-20 h-16 ${colors.icon.brand} rounded-lg border ${colors.elevation.border.selected} hover:scale-105`,
                  isCreateButtonDraggedOver
                    ? 'bg-shrek/70 text-k2-gray-500 border-k2-gray-500'
                    : `${colors.border.brand}`,
                )}
                onClick={() => createCollection([])}
                onDragOver={handleCreateButtonDragOver}
                onDragLeave={handleCreateButtonDragLeave}
                onDrop={(event) => handleDrop(event)}
              >
                {isCreateButtonDraggedOver ? (
                  <div className='flex justify-center items-center w-full h-full'>
                    <UploadIcon size={32} />
                  </div>
                ) : (
                  <div className='flex justify-center items-center w-full h-full'>
                    <AddCollectionIcon size={22} />
                  </div>
                )}
              </div>
              {[...collections]
                .reverse()
                .map((collection: Collection, index: number) => (
                  <Draggable
                    key={collection.collectionId}
                    draggableId={collection.collectionId}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            opacity: snapshot.isDragging ? 0 : 1,
                          }}
                        >
                          <CollectionPreviewFrame
                            collection={collection}
                            onClick={() =>
                              onCollectionClick(collection.collectionId)
                            }
                            onDrop={(event) =>
                              handleDrop(event, collection.collectionId)
                            }
                          />
                        </div>
                        {snapshot.isDragging &&
                          createPortal(
                            <div
                              style={{
                                ...provided.draggableProps.style,
                                position: 'fixed',
                                pointerEvents: 'none',
                                zIndex: getMaxNodeZIndex(),
                              }}
                            >
                              <CollectionPreviewFrame
                                collection={collection}
                                onClick={() => {}}
                                onDrop={() => {}}
                              />
                            </div>,
                            document.body,
                          )}
                      </>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  )
}

CollectionsPreview.displayName = 'CollectionsPreview'
