import { Node, useReactFlow, XYPosition } from '@xyflow/react'
import { useCallback } from 'react'
import { v4 as uuid } from 'uuid'

import { HOTKEYS } from '../../constants'
import { useCanvasContext } from '../../context'
import { useAnalytics, useHotkeys, useNodePosition } from '../../hooks'
import {
  AnalyticsEvent,
  ElementType,
  MediaForm,
  ModelType,
  NodeOrigin,
  NodeType,
  PresetFlow,
} from '../../types'
import { MediaNodeData } from '@/components/k2/Nodes'

/**
 * This hook offers helper functions for mangaging nodes (duplicate, delete, recreate)
 */

export const useNodeUtility = () => {
  const { getNode, setNodes, getNodes, addNodes, setEdges, updateNode } =
    useReactFlow()
  const { trackNodeEvent } = useAnalytics()
  const { getNewNodePosition } = useNodePosition()
  const { selectedNodeIds } = useCanvasContext()

  /**
   * Calculates the max node zIndex
   * Ensures the generated node zIndex is higher than other nodes' zIndex
   */
  const getMaxNodeZIndex = useCallback(() => {
    const currentNodes = getNodes()
    const maxZIndex = Math.max(...currentNodes.map((n) => n.zIndex || 1)) + 1
    return maxZIndex
  }, [getNodes])

  useHotkeys({
    [HOTKEYS.COPY]: {
      handler: (event: KeyboardEvent) => {
        if (Array.from(selectedNodeIds).length) {
          event.preventDefault()
          event.stopImmediatePropagation()

          const currentNodes = getNodes()
          navigator.clipboard.writeText(
            JSON.stringify(
              currentNodes.filter((node: Node) => selectedNodeIds.has(node.id)),
            ),
          )
        }
      },
    },
    [HOTKEYS.PASTE]: {
      handler: (event: KeyboardEvent) => {
        event.preventDefault()
        event.stopImmediatePropagation()

        navigator.clipboard.readText().then((clipText: string) => {
          try {
            let nodesToAdd = JSON.parse(clipText).map((node: Node) => {
              const position = {
                x: node.position.x + 50,
                y: node.position.y + 50,
              }
              const tempNode = {
                ...node,
                id: `${node.id}-d${Date.now()}`,
                selected: false,
                dragging: false,
                position,
                style: {
                  ...node.style,
                  boxShadow: 'none',
                },
              }

              return tempNode
            })
            addNodes(nodesToAdd)
          } catch (err: any) {
            console.error(
              'Failed to parse clipboard text to node type',
              err,
              'Response:',
              err.response,
            )
          }
        })
      },
    },
  })

  /**
   * Calculates the next available node index.
   * Ensures that the new node is assigned a unique and sequential index,
   * filling any gaps in the sequence of existing node indexes.
   */
  const getNextNodeIndex = useCallback(() => {
    const currentNodes = getNodes()
    if (currentNodes.length === 0) return 1

    const indexList = currentNodes
      .map((node) => node.data.nodeIndex as number)
      .sort((a, b) => a - b)
    const missedIndex = indexList.findIndex(
      (value, index) => value !== index + 1,
    )
    return missedIndex === -1 ? currentNodes.length + 1 : missedIndex + 1
  }, [getNodes])

  /**
   * Creates a new node object with a unique ID, position, and data.
   *
   * Parameters:
   * - nodeType: The type of the node (string).
   * - modelType: The model type associated with the node.
   * - position: The XY coordinates for the node's position.
   * - formValues: Optional form values to include in the node's data.
   * - elements: Optional elements to include in the node's data.
   *
   * The new node includes:
   * - A unique ID combining the node type and a UUID.
   * - The specified position.
   * - Data including model type, node index, and optionally form values and elements.
   */
  const getNewNode = useCallback(
    (
      nodeType: string,
      modelType: ModelType,
      name: string,
      position: XYPosition,
      formValues?: MediaForm,
      elements?: ElementType[],
    ): Node => {
      const data =
        formValues && elements
          ? {
              modelType,
              name,
              nodeIndex: getNextNodeIndex(),
              formValues,
              elements,
              slotId: '',
              startCollapsed: false,
            }
          : { modelType, name, nodeIndex: getNextNodeIndex() }

      const newNode: Node = {
        id: `${nodeType}-${uuid()}`,
        zIndex: getMaxNodeZIndex(),
        position,
        data,
        type: nodeType,
      }

      return newNode
    },
    [getNextNodeIndex, getMaxNodeZIndex],
  )

  /**
   * Add a preset to the canvas
   */
  const addPreset = useCallback(
    (preset: PresetFlow, name: string, targetPosition?: XYPosition) => {
      const { modelType, formValues, elements } = preset.flow

      const { position } = getNewNodePosition(targetPosition)

      const newNode: Node = getNewNode(
        NodeType.FlowNode,
        modelType,
        name,
        position,
        formValues,
        elements,
      )

      addNodes(newNode)
      trackNodeEvent(newNode, AnalyticsEvent.NodeAdded, {
        nodeOrigin: NodeOrigin.AddedPreset,
      })
    },
    [addNodes, getNewNode, getNewNodePosition, trackNodeEvent],
  )

  const duplicateNodeById = useCallback(
    (id: string) => {
      const node = getNode(id)
      if (node) {
        const position = {
          x: node.position.x + 50,
          y: node.position.y + 50,
        }

        const newNode = {
          ...node,
          id: `${node.id}-d${Date.now()}`,
          selected: false,
          dragging: false,
          position,
          style: {
            ...node.style,
            boxShadow: 'none', // remove box shadow when duplicate selected node
          },
        }

        addNodes(newNode)
        trackNodeEvent(newNode, AnalyticsEvent.NodeAdded, {
          nodeOrigin: NodeOrigin.DuplicatedNode,
        })
      }
    },
    [getNode, addNodes, trackNodeEvent],
  )

  const deleteNodeById = useCallback(
    (id: string) => {
      const currentNode = getNode(id)

      // Setting the width and height to zero before removal is necessary
      // because the selection area persists even after the node is deleted.
      updateNode(currentNode.id, {
        position: { x: 0, y: 0 },
        measured: { width: 0, height: 0 },
      })

      setNodes((nodes) => nodes.filter((node) => node.id !== id))
      setEdges((edges) =>
        edges.filter((edge) => edge.source !== id && edge.target !== id),
      )
      trackNodeEvent(currentNode, AnalyticsEvent.NodeRemoved, {})
    },
    [getNode, updateNode, setNodes, setEdges, trackNodeEvent],
  )

  const recreateFlowById = useCallback(
    (id: string) => {
      const node = getNode(id)
      const { modelType, formValues, elements, name } = (node.data.media as any)
        .flow
      const uuidValue = uuid()
      const newNode = {
        id: `${NodeType.FlowNode}-${uuidValue}`,
        position: {
          x: node.position.x,
          y: node.position.y + 150,
        },
        data: {
          modelType,
          formValues,
          elements,
          media: node.data.media,
          slotId: '',
          nodeIndex: uuidValue,
          startCollapsed: false,
          lastTempId: node.id,
          name,
        },
        type: NodeType.FlowNode,
      }
      addNodes(newNode)
      trackNodeEvent(newNode, AnalyticsEvent.NodeAdded, {
        nodeOrigin: NodeOrigin.RecreatedFlow,
      })
    },
    [addNodes, getNode, trackNodeEvent],
  )

  const duplicateSelectedNodes = useCallback(() => {
    Array.from(selectedNodeIds).forEach((id) => {
      duplicateNodeById(id)
    })
  }, [selectedNodeIds, duplicateNodeById])

  const deleteSelectedNodes = useCallback(() => {
    Array.from(selectedNodeIds).forEach((id) => {
      deleteNodeById(id)
    })
  }, [selectedNodeIds, deleteNodeById])

  const recreateSelectedFlows = useCallback(() => {
    Array.from(selectedNodeIds).forEach((id) => {
      recreateFlowById(id)
    })
  }, [selectedNodeIds, recreateFlowById])

  const isSingleNodeMediaNodeWithFlow = useCallback(
    (id: string) => {
      const node = getNode(id)
      return (
        node?.type === NodeType.MediaNode &&
        (node?.data as MediaNodeData)?.media?.flow
      )
    },
    [getNode],
  )

  const isAllSelectedNodesMediaNodesWithFlow = useCallback(() => {
    return Array.from(selectedNodeIds).every((id) => {
      return isSingleNodeMediaNodeWithFlow(id)
    })
  }, [selectedNodeIds, isSingleNodeMediaNodeWithFlow])

  const isSingleNodeSelected = useCallback(
    (id: string | null) => {
      if (id === null) return false

      return (
        (id && selectedNodeIds.size === 0) || // node dragging individually
        (selectedNodeIds.has(id) && selectedNodeIds.size === 1) || // node dragging in selection
        !selectedNodeIds.has(id) // node is not in selection but user opens context menu on this node
      )
    },
    [selectedNodeIds],
  )

  const isNodeInSelection = useCallback(
    (id: string) => {
      return selectedNodeIds.has(id)
    },
    [selectedNodeIds],
  )

  const anyNodesSelected = useCallback(() => {
    return selectedNodeIds.size > 0
  }, [selectedNodeIds])

  return {
    isSingleNodeSelected,
    isNodeInSelection,
    duplicateNodeById,
    deleteNodeById,
    recreateFlowById,
    duplicateSelectedNodes,
    deleteSelectedNodes,
    recreateSelectedFlows,
    isAllSelectedNodesMediaNodesWithFlow,
    isSingleNodeMediaNodeWithFlow,
    anyNodesSelected,
    getNextNodeIndex,
    getMaxNodeZIndex,
    getNewNode,
    addPreset,
  }
}
