import { Node } from '@xyflow/react'
import { debounce } from 'lodash'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { CURRENT_VERSION, PAGEVIEW } from '../constants'
import { useUserAccountContext } from '../context/userContext'
import {
  AnalyticsEvent,
  EventProperties,
  BaseEventProperties,
  BaseNodeEventProperties,
} from '../types'
import { analytics } from '../utils/analytics'

export function useAnalytics() {
  const { currentUser } = useUserAccountContext()
  const { canvasId } = useParams()

  const identifyUser = debounce((user?: any) => {
    if (user._id) {
      const properties: Record<string, string> = {
        ...user,
        version: CURRENT_VERSION,
        source: 'FE',
      }

      analytics.identify(user.distinctId, properties)
    }
  }, 500)

  const trackPage = (
    page: string,
    additionalProperties?: Record<string, string>,
  ) => {
    const properties: Record<string, string> = {
      pathname: window.location.pathname,
      source: 'FE',
      version: CURRENT_VERSION,
      eventType: PAGEVIEW,
      ...additionalProperties,
    }
    analytics.page(page, properties)
  }

  /**
   * Tracks an analytics event for either canvas or node.
   *
   * @template T
   * @param {T} eventName - The name of the event to be tracked. Must be a valid AnalyticsEvent.
   * @param {Partial<EventProperties[T]>} eventProperties - Properties for the event, including any overrides for base properties.
   *
   * @throws {Error} If invalid parameters are provided.
   *
   * @remarks
   * This function provides default values for base properties (canvas_id, user_uuid,
   * subscription_type, platform, and data), but allows them to be overridden if provided
   * in the eventProperties parameter. It can handle both canvas and node events.
   */
  const trackEvent = useCallback(
    <T extends AnalyticsEvent>(
      eventName: T,
      eventProperties: Partial<EventProperties[T]>,
    ): void => {
      const baseProperties: BaseEventProperties = {
        canvasId,
        distinctId: currentUser.distinctId,
        subscriptionTier: currentUser.subscriptionTierDescription,
        platform: 'web',
        data: {}, // You may want to populate this with relevant data
      }

      const properties: EventProperties[T] = {
        ...baseProperties,
        ...eventProperties,
      } as EventProperties[T]

      analytics.track(eventName, {
        ...properties,
        source: 'FE',
        version: CURRENT_VERSION,
      })
    },
    [canvasId, currentUser?.subscriptionType, currentUser?.uuid],
  )

  /**
   * Tracks an analytics event for a specific node in the canvas.
   *
   * @template T
   * @param {Node} node - The node object for which the event is being tracked.
   * @param {T} eventName - The name of the event to be tracked. Must be a valid AnalyticsEvent.
   * @param {Omit<EventProperties[T], keyof BaseNodeEventProperties>} eventProperties - Additional properties for the event, excluding base node properties.
   *
   * @throws {Error} If invalid parameters are provided.
   *
   * @remarks
   * This function automatically includes base node properties such as canvas_id, user_uuid,
   * subscription_type, platform, model_type, node_id, node_type, position, and data.
   * The eventProperties parameter should only include additional properties specific to the event.
   */
  type NodeAnalyticsEvent = Extract<
    AnalyticsEvent,
    AnalyticsEvent.NodeAdded | AnalyticsEvent.NodeRemoved
  >

  const trackNodeEvent = useCallback(
    <T extends NodeAnalyticsEvent>(
      node: Node,
      eventName: T,
      eventProperties: Partial<
        Omit<EventProperties[T], keyof BaseNodeEventProperties>
      >,
    ): void => {
      const data = node.data.media ?? node.data

      const baseNodeProperties = {
        nodeId: node.id,
        nodeType: node.type,
        position: { x: node.position.x, y: node.position.y },
        distinctId: currentUser.distinctId,
        subscriptionTier: currentUser.subscriptionTierDescription,
        canvasId,
        data,
      }

      const properties: EventProperties[T] = {
        ...baseNodeProperties,
        ...eventProperties,
      } as EventProperties[T]

      trackEvent(eventName, properties)
    },
    [trackEvent],
  )

  return { identifyUser, trackPage, trackNodeEvent, trackEvent }
}
