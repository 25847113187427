import {
  Collection,
  CollectionAction,
  RenameRequestBody,
  AddMediaRequestBody,
  RemoveMediaRequestBody,
} from '@kaiber/shared-types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { toast } from 'react-toastify'

import { CollectionService } from '../../services/CollectionService'
import {
  collectionUtils,
  ALL_COLLECTION_IDS_QUERY_KEY,
} from '../../utils/collectionUtils'

export const useMutateCollection = () => {
  const queryClient = useQueryClient()

  const invalidateCollectionQuery = (collectionId: string) => {
    queryClient.invalidateQueries({
      queryKey: collectionUtils.keyOf(collectionId),
    })
  }

  const invalidateAllCollectionQueries = () => {
    queryClient.invalidateQueries({ queryKey: ALL_COLLECTION_IDS_QUERY_KEY })
    queryClient.invalidateQueries({ queryKey: ['collection'] })
  }

  const { mutateAsync: createCollection } = useMutation({
    mutationFn: (mediaIds: string[]) =>
      CollectionService.createCollection(mediaIds),
    onMutate: async (mediaIds) => {
      // Optimistically add a new collection (`tempCollection`) before backend returns
      const tempCollection: Collection = {
        collectionId: `temp-${Date.now()}`,
        ownerId: null, // This will be set by the server
        name: 'New Collection', // Use a default name
        mediaIds,
      }

      queryClient.setQueryData(
        ALL_COLLECTION_IDS_QUERY_KEY,
        (old: string[] | undefined) => [
          ...(old || []),
          tempCollection.collectionId,
        ],
      )
      queryClient.setQueryData(
        collectionUtils.keyOf(tempCollection.collectionId),
        tempCollection,
      )

      return { tempCollection }
    },
    onSuccess: (newCollection, _, context) => {
      if (context?.tempCollection) {
        queryClient.setQueryData(
          ALL_COLLECTION_IDS_QUERY_KEY,
          (old: string[] | undefined) =>
            old?.map((c) =>
              c === context.tempCollection.collectionId
                ? newCollection.collectionId
                : c,
            ),
        )
      }
      queryClient.setQueryData(
        collectionUtils.keyOf(newCollection.collectionId),
        newCollection,
      )
    },
    onError: (_, __, context) => {
      if (context?.tempCollection) {
        queryClient.setQueryData(
          ALL_COLLECTION_IDS_QUERY_KEY,
          (old: Collection[] | undefined) =>
            old?.filter(
              (c) => c.collectionId !== context.tempCollection.collectionId,
            ),
        )
      }
    },
  })

  const { mutateAsync: updateCollection } = useMutation({
    mutationFn: ({
      collectionId,
      action,
      payload,
    }: {
      collectionId: string
      action: CollectionAction
      payload: RenameRequestBody | AddMediaRequestBody | RemoveMediaRequestBody
    }) => {
      return CollectionService.updateCollection(collectionId, action, payload)
    },
    onMutate: async ({ collectionId, action, payload }) => {
      await queryClient.cancelQueries({
        queryKey: collectionUtils.keyOf(collectionId),
      })

      const previousCollection = queryClient.getQueryData<Collection>(
        collectionUtils.keyOf(collectionId),
      )

      if (previousCollection) {
        const updatedCollection = { ...previousCollection }

        switch (action) {
          case 'RENAME':
            updatedCollection.name =
              (payload as { name: string }).name || updatedCollection.name
            break
          case 'ADD_MEDIA':
            {
              const { mediaIds, index = 0 } = payload as AddMediaRequestBody
              updatedCollection.mediaIds = [
                ...updatedCollection.mediaIds.slice(0, index),
                ...mediaIds,
                ...updatedCollection.mediaIds.slice(index),
              ]
            }
            break
          case 'REMOVE_MEDIA':
            updatedCollection.mediaIds = updatedCollection.mediaIds.filter(
              (id) =>
                !(payload as RemoveMediaRequestBody).mediaIds.includes(id),
            )
            break
        }

        queryClient.setQueryData(
          collectionUtils.keyOf(collectionId),
          updatedCollection,
        )
      }

      return { previousCollection }
    },
    onError: (err, { collectionId }, context) => {
      if (context?.previousCollection) {
        queryClient.setQueryData(
          collectionUtils.keyOf(collectionId),
          context.previousCollection,
        )
      }
      toast.error(`Failed to update collection: ${err}`)
    },
    onSettled: (_, __, { collectionId }) => {
      invalidateCollectionQuery(collectionId)
    },
  })

  // TODO Should this also handle deleting the CollectionNode from the canvas if it exists?
  const { mutateAsync: deleteCollection } = useMutation({
    mutationFn: (collectionId: string) =>
      CollectionService.deleteCollection(collectionId),
    onMutate: async (collectionId) => {
      await queryClient.cancelQueries({ queryKey: ['collections'] })
      const previousCollections = queryClient.getQueryData<Collection[]>([
        'collections',
      ])
      if (previousCollections) {
        queryClient.setQueryData<Collection[]>(
          ['collections'],
          previousCollections.filter((c) => c.collectionId !== collectionId),
        )
      }
      return { previousCollections }
    },
    onError: (err, _, context) => {
      if (context?.previousCollections) {
        queryClient.setQueryData(['collections'], context.previousCollections)
      }
      toast.error(`Failed to delete collection: ${err}`)
    },
    onSettled: () => {
      invalidateAllCollectionQueries()
    },
  })

  const updateCollectionLocally = useCallback(
    (
      collectionId: string,
      action: CollectionAction,
      payload: AddMediaRequestBody,
    ) => {
      queryClient.setQueryData<Collection>(
        collectionUtils.keyOf(collectionId),
        (oldData) => {
          if (!oldData) return oldData

          const updatedCollection = { ...oldData }

          switch (action) {
            case 'ADD_MEDIA':
              {
                const { mediaIds, index = 0 } = payload as AddMediaRequestBody
                updatedCollection.mediaIds = [
                  ...updatedCollection.mediaIds.slice(0, index),
                  ...mediaIds,
                  ...updatedCollection.mediaIds.slice(index),
                ]
              }
              break
          }

          return updatedCollection
        },
      )
    },
    [queryClient],
  )

  /**
   * Replaces a temporary media ID with a new media ID in a collection and prepends it to the collection's media list.
   *
   * This function performs two operations:
   * 1. Replaces the temporary media ID with the new media ID.
   * 2. Moves the new media ID to the beginning of the collection's media list.
   *
   * It uses optimistic updates to provide a responsive user experience and includes
   * error handling to revert changes if the server update fails.
   *
   * @param {Object} params - The parameters for the mutation.
   * @param {string} params.collectionId - The ID of the collection to update.
   * @param {string} params.tempMediaId - The temporary media ID to replace.
   * @param {string} params.newMediaId - The new media ID to insert.
   *
   * @returns {Promise<void>} A promise that resolves when the mutation is complete.
   *
   * @throws Will throw an error if the mutation fails.
   */
  const { mutateAsync: replaceAndPrependMedia } = useMutation({
    mutationFn: ({
      collectionId,
      tempMediaId: _tempMediaId,
      newMediaId,
    }: {
      collectionId: string
      tempMediaId: string
      newMediaId: string
    }) => {
      return CollectionService.updateCollection(
        collectionId,
        'ADD_MEDIA' as CollectionAction,
        { mediaIds: [newMediaId], index: 0 } as AddMediaRequestBody,
      )
    },
    onMutate: async ({ collectionId, tempMediaId, newMediaId }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: collectionUtils.keyOf(collectionId),
      })

      // Snapshot the previous value
      const previousCollection = queryClient.getQueryData<Collection>(
        collectionUtils.keyOf(collectionId),
      )

      // Optimistically update to the new value
      if (previousCollection) {
        const updatedCollection = { ...previousCollection }
        updatedCollection.mediaIds = [
          newMediaId,
          ...updatedCollection.mediaIds.filter(
            (id) => id !== tempMediaId && id !== newMediaId,
          ),
        ]

        queryClient.setQueryData(
          collectionUtils.keyOf(collectionId),
          updatedCollection,
        )
      }

      return { previousCollection }
    },
    onError: (err, { collectionId }, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if (context?.previousCollection) {
        queryClient.setQueryData(
          collectionUtils.keyOf(collectionId),
          context.previousCollection,
        )
      }
      toast.error(`Failed to replace and prepend media: ${err}`)
    },
  })

  const { mutateAsync: addMediaBulkAsync } = useMutation({
    mutationFn: ({
      collectionId,
      mediaIds,
      index,
    }: {
      collectionId: string
      mediaIds: string[]
      index?: number
    }) => {
      return CollectionService.updateCollection(
        collectionId,
        'ADD_MEDIA' as CollectionAction,
        { mediaIds, index } as AddMediaRequestBody,
      )
    },
    onMutate: async ({ collectionId, mediaIds, index }) => {
      // Cancel any outgoing refetches
      await queryClient.cancelQueries({
        queryKey: collectionUtils.keyOf(collectionId),
      })

      // Snapshot the previous value
      const previousCollection = queryClient.getQueryData<Collection>(
        collectionUtils.keyOf(collectionId),
      )

      // Optimistically update to the new value
      if (previousCollection) {
        const updatedCollection = { ...previousCollection }
        const uniqueNewMediaIds = [...new Set(mediaIds)]
        const existingMediaIds = updatedCollection.mediaIds

        // Remove all instances of new media IDs from existing media IDs
        const filteredExistingMediaIds = existingMediaIds.filter(
          (id) => !uniqueNewMediaIds.includes(id),
        )

        let updatedMediaIds: string[]

        if (
          index !== undefined &&
          index >= 0 &&
          index <= filteredExistingMediaIds.length
        ) {
          // Insert the new mediaIds at the specified index
          updatedMediaIds = [
            ...filteredExistingMediaIds.slice(0, index),
            ...uniqueNewMediaIds,
            ...filteredExistingMediaIds.slice(index),
          ]
        } else {
          // Append the new mediaIds to the end
          updatedMediaIds = [...filteredExistingMediaIds, ...uniqueNewMediaIds]
        }

        updatedCollection.mediaIds = updatedMediaIds

        queryClient.setQueryData(
          collectionUtils.keyOf(collectionId),
          updatedCollection,
        )
      }

      return { previousCollection }
    },
    onError: (err, { collectionId }, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if (context?.previousCollection) {
        queryClient.setQueryData(
          collectionUtils.keyOf(collectionId),
          context.previousCollection,
        )
      }
      toast.error(
        `There was an issue adding your media, please try again later.`,
      )
      console.error(err)
    },
    onSettled: (_, __, { collectionId }) => {
      invalidateCollectionQuery(collectionId)
    },
  })

  const removeMediaBulk = useCallback(
    (collectionId: string, mediaIds: string[]) => {
      return updateCollection({
        collectionId,
        action: 'REMOVE_MEDIA' as CollectionAction,
        payload: { mediaIds },
      })
    },
    [],
  )

  const prependMedia = useCallback(
    (collectionId: string, mediaId: string) => {
      return addMediaBulkAsync({
        collectionId,
        mediaIds: [mediaId],
        index: 0,
      })
    },
    [addMediaBulkAsync],
  )

  // WARNING: To avoid infinite re-rendering due to React's dependency array,
  // Do not return the result of useMutation here! See https://tanstack.com/query/latest/docs/eslint/no-unstable-deps
  return {
    prependMedia,
    createCollection,
    updateCollection,
    deleteCollection,
    updateCollectionLocally,
    replaceAndPrependMedia,
    removeMediaBulk,
  }
}
