import { Collection } from '@kaiber/shared-types'
import { useState } from 'react'

import { UploadIcon } from '../../../images/icons/UploadIcon'
import { mediaStore } from '../../../stores'
import { cn } from '../../../utils'
import { Media, MediaType, Video } from '@/types'

interface CollectionPreviewFrameProps {
  collection: Collection
  onClick: () => void
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void
}

const DEFAULT_THUMBNAIL_URL =
  'https://website-public-assets.kybercorp.org/presets/default-thumbnail.png'

export const CollectionPreviewFrame = ({
  collection,
  onClick,
  onDrop,
}: CollectionPreviewFrameProps) => {
  const [isDraggedOver, setIsDraggedOver] = useState(false)
  const mediaId = collection.mediaIds[0]
  const media: Media = mediaStore.useMedia(mediaId)

  const previewSource =
    media?.type === MediaType.Video
      ? (media as Video).thumbnailSource
      : media?.source

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'copy'
    setIsDraggedOver(true)
  }

  const handleDragLeave = () => {
    setIsDraggedOver(false)
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    setIsDraggedOver(false)
    onDrop(event)
  }

  return (
    <div
      className='w-20 h-16 rounded-lg overflow-hidden cursor-pointer relative hover:scale-105'
      onClick={onClick}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <img
        src={previewSource || DEFAULT_THUMBNAIL_URL}
        alt={`Preview of ${collection.name}`}
        className='w-full h-full object-cover'
      />
      <div
        className={cn(
          'absolute bottom-0 left-0 right-0 text-white text-xs p-1 truncate',
          previewSource && 'bg-black/60',
        )}
      >
        {collection.name}
      </div>
      {isDraggedOver && (
        <div className='absolute inset-0 flex justify-center items-center bg-shrek/60 text-k2-gray-500'>
          <UploadIcon size={32} />
        </div>
      )}
    </div>
  )
}

CollectionPreviewFrame.displayName = 'CollectionPreviewFrame'
