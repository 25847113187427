import { getMedia } from '../api'
import { Media, Status, Video } from '../types'
import { isSieveUrl, isTempMedia, signedUrlExpired } from './mediaUtils'

/**
 * A class that polls the medias every 5 seconds until it's no longer in `Pending` status.
 * It also polls once if the signedUrls expired so that a media can be updated.
 *
 * In the future, we may want features like batch polling (because there can be too many
 * pending videos on canvas), and giving up after a certain number of failed attempts, etc.
 * And this class can be used to implement those features becuase it has central knowledge
 * over all the polling requests.
 */
export class MediaPoller {
  private pollIntervalMs = 5000

  private mediaIdsBeingPolled = new Map<
    string,
    ReturnType<typeof setInterval>
  >()

  private nothingToPoll(media: Media): boolean {
    return (
      isTempMedia(media) ||
      (media.status !== Status.Pending &&
        !signedUrlExpired(media.source) &&
        !signedUrlExpired((media as Video).thumbnailSource) &&
        !isSieveUrl(media.source))
    )
  }

  private tooOldToPoll(media: Media): boolean {
    // If a media was created >1 week ago and is still pending, we stop polling it.
    // This is to avoid polling a media stuck in pending state forever due to unknown backend issues.
    const ONE_WEEK_MS = 7 * 24 * 60 * 60 * 1000
    return Date.now() - new Date(media.createdAt).getTime() > ONE_WEEK_MS
  }

  /**
   * Start async polling the media (certain conditions must be met;
   * otherwise this function is a no-op. For example: this Media is
   * pending, or its signalURLs expired)
   * @param media media to poll
   * @param onPulledOnce callback to invoke when one pull is completed.
   * @param onMediaPollingStopped callback to invoke when Media's status switched from Pending to something else .
   */
  public startPolling(
    media: Media,
    onPulledOnce: (m: Media) => void,
    onMediaPollingStopped?: (m: Media) => void,
  ) {
    if (this.mediaIdsBeingPolled.has(media.mediaId)) return // Already polling
    if (this.nothingToPoll(media)) return

    const mediaId = media.mediaId // to avoid periodicFn using the mutable `media` object
    const periodicFn = async () => {
      const updatedMedia = await getMedia({ mediaId })
      onPulledOnce(updatedMedia)
      if (updatedMedia.status !== Status.Pending && onMediaPollingStopped) {
        onMediaPollingStopped(updatedMedia)
      }
      if (
        updatedMedia.status !== Status.Pending ||
        this.tooOldToPoll(updatedMedia)
      ) {
        this.stopPolling(mediaId)
      }
    }

    const intervalId = setInterval(periodicFn, this.pollIntervalMs)
    this.mediaIdsBeingPolled.set(mediaId, intervalId)
  }

  public stopPolling(mediaId: string) {
    clearInterval(this.mediaIdsBeingPolled.get(mediaId))
    this.mediaIdsBeingPolled.delete(mediaId)
  }
}
