import { Logtail } from '@logtail/browser'
import cookies from 'browser-cookies'
import {
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
  ReactNode,
} from 'react'
import { useMixpanel } from 'react-mixpanel-browser'

import { API_ENDPOINTS } from '../constants'
import { useAnalytics, useInitialLoad } from '../hooks'
import {
  getCurrentUser,
  setRewardfulObjectId,
  IMPERSONATING_ID_KEY,
  setAccessToken,
  getAccessToken,
  removeAuthTokens,
} from '../services/AuthService'
import http, {
  setAuthorization,
  unsetAuthorization,
} from '../services/HttpService'
import { User, UserPreferences } from '../types'
import { impactIdentify } from '../utils/impact_util'

const logtail = new Logtail(process.env.REACT_APP_PINO_LOGGER_TOKEN as string)

interface UserAccountContextType {
  currentUser: User | null
  isAuthenticated: boolean
  isImpersonating: boolean
  isOnboardingCompleted: boolean
  isSubscriptionActive: boolean
  isFreeTrial: boolean
  featureToggles: Record<string, boolean>
  setCurrentUser: (user: User | null) => void
  refreshCredits: () => Promise<void>
  refreshVideoCount: () => Promise<void>
  updateUserPreferences: (newPreferences: UserPreferences) => Promise<void>
  setMixpanelMemoryIds: (memoryIds: string[]) => void
}

export const UserAccountContext = createContext<UserAccountContextType>({
  currentUser: null,
  isAuthenticated: false,
  isImpersonating: false,
  isOnboardingCompleted: false,
  isSubscriptionActive: false,
  isFreeTrial: false,
  featureToggles: {},
  setCurrentUser: () => {},
  refreshCredits: async () => {},
  refreshVideoCount: async () => {},
  updateUserPreferences: async () => {},
  setMixpanelMemoryIds: () => {},
})

interface UserAccountProviderProps {
  children: ReactNode
}

export const UserAccountProvider: React.FC<UserAccountProviderProps> = ({
  children,
}) => {
  const { identifyUser } = useAnalytics()
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [featureToggles, setFeatureToggles] = useState<Record<string, boolean>>(
    {},
  )
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isImpersonating, setIsImpersonating] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const mixpanel = useMixpanel()

  const isSubscriptionActive = useMemo(() => {
    return currentUser?.subscriptionType === 'Standard'
  }, [currentUser])

  const isFreeTrial = useMemo(() => {
    if (currentUser?.subscriptionType === 'Standard') {
      return (
        currentUser.subscriptionTierDescription.startsWith('Explorer') &&
        currentUser.subscriptionStatus === 'trialing'
      )
    }
    return false
  }, [currentUser])

  const isOnboardingCompleted = useMemo(() => {
    return !!currentUser?.onboardingCompletedAt
  }, [currentUser])

  const refreshCredits = useCallback(async () => {
    try {
      const response = await http.get<{ credits: number }>('/api/get_credits')
      const credits = response.data.credits
      setCurrentUser((user) => (user ? { ...user, credits } : null))
    } catch (error) {
      console.error('Error refreshing credits:', error)
    }
  }, [])

  const refreshVideoCount = useCallback(async () => {
    try {
      const response = await http.get<{ videoCount: number }>(
        '/api/get_video_count',
      )
      const memoriesCount = response.data.videoCount
      setCurrentUser((user) => (user ? { ...user, memoriesCount } : null))
    } catch (error) {
      console.error('Error refreshing video count:', error)
    }
  }, [])

  const updateUserPreferences = useCallback(
    async (newPreferences: UserPreferences) => {
      const currentPreferences = currentUser?.preferences || {}

      try {
        const response = await http.patch(
          API_ENDPOINTS.UPDATE_USER_PREFERENCES,
          newPreferences,
        )
        setCurrentUser((user) =>
          user ? { ...user, preferences: response.data } : null,
        )
      } catch (error) {
        console.error('Error updating user preferences:', error)
        setCurrentUser((user) =>
          user ? { ...user, preferences: currentPreferences } : null,
        )
        throw error
      }
    },
    [currentUser],
  )

  const setMixpanelMemoryIds = useCallback(
    (memoryIds: string[]) => {
      if (currentUser?.distinctId) {
        mixpanel.identify(currentUser.distinctId)
        mixpanel.people.set({ memoryIds })
      }
    },
    [currentUser, mixpanel],
  )

  useInitialLoad(async () => {
    const accessToken = getAccessToken()
    const impersonateId =
      window.localStorage.getItem(IMPERSONATING_ID_KEY) || false

    if (accessToken) {
      setAuthorization(accessToken)

      try {
        const response = await getCurrentUser()

        if (process.env.NODE_ENV !== 'development') {
          logtail.info(
            `${response?.data?.user?.distinctId} felog userContext.js getCurrentUser()`,
            { response },
          )
          logtail.flush()
        }

        const { user, token: updatedToken, featureToggles } = response.data

        setAccessToken(updatedToken)
        setCurrentUser(user)
        setIsAuthenticated(true)
        setFeatureToggles(featureToggles)

        if (impersonateId) {
          setIsImpersonating(true)
        }

        impactIdentify({ user })
        identifyUser(user)

        const rewardfulReferral = JSON.parse(
          cookies.get('rewardful.referral') || 'null',
        )
        const kaiberRewardfulObjectSet = cookies.get('kaiberRewardfulObjectSet')
        if (rewardfulReferral && !kaiberRewardfulObjectSet) {
          const rewardfulObjectId = rewardfulReferral?.id
          try {
            await setRewardfulObjectId(rewardfulObjectId)
            cookies.set('kaiberRewardfulObjectSet', '1')
          } catch (err) {
            logtail.info('felog userContext.js setRewardfulObjectId() error', {
              error: err,
              rewardfulObjectId,
              rewardfulReferral,
              kaiberRewardfulObjectSet,
            })
            logtail.flush()
          }
        }
      } catch (err: any) {
        console.error(err)
        if (err.response) {
          switch (err.response.status) {
            case 401:
            case 403:
            case 404:
              switch (err.response.data.code) {
                case 'BETA_ACCESS_DENIED':
                case 'GEOBLOCKED':
                case 'ACCOUNT_BANNED':
                  setErrorMessage(err.response.data.message)
                  break
                default: {
                  removeAuthTokens()
                  const distinctId =
                    window.localStorage.getItem(IMPERSONATING_ID_KEY) || false
                  if (distinctId) {
                    window.localStorage.removeItem(IMPERSONATING_ID_KEY)
                  }
                  unsetAuthorization()
                }
              }
              break
            default:
              logtail.info(`felog userContext.js error`, { error: err })
              logtail.flush()
              setErrorMessage('Oops! Something went wrong.')
          }
        } else {
          setErrorMessage(
            'We were unable to complete your request. Please check your connection and try again.',
          )
        }
      } finally {
        setIsLoading(false)
      }
    } else {
      impactIdentify({ user: null })
      setIsLoading(false)
    }
  })

  const contextValue = useMemo(
    () => ({
      currentUser,
      featureToggles,
      setCurrentUser,
      isAuthenticated,
      isImpersonating,
      refreshCredits,
      refreshVideoCount,
      isSubscriptionActive,
      isFreeTrial,
      isOnboardingCompleted,
      updateUserPreferences,
      setMixpanelMemoryIds,
    }),
    [
      currentUser,
      featureToggles,
      isAuthenticated,
      isImpersonating,
      refreshCredits,
      refreshVideoCount,
      isSubscriptionActive,
      isFreeTrial,
      isOnboardingCompleted,
      updateUserPreferences,
      setMixpanelMemoryIds,
    ],
  )

  if (isLoading) {
    return <div>Loading...</div> // Or your preferred loading indicator
  }

  if (errorMessage) {
    return <div>Error: {errorMessage}</div> // Or your preferred error display
  }

  return (
    <UserAccountContext.Provider value={contextValue}>
      {children}
    </UserAccountContext.Provider>
  )
}

export const useUserAccountContext = () => useContext(UserAccountContext)

UserAccountContext.displayName = 'UserAccountContext'
