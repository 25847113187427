import { XYPosition } from '@xyflow/react'
import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react'

import { fetchSubjectPrompts } from '../api'
import { API_ENDPOINTS } from '../constants'
import { useInitialLoad, useNodeUtility } from '../hooks'
import http from '../services/HttpService'
import {
  CompatiblePresetFlow,
  GetPromptsResponse,
  Media,
  MediaType,
  PresetFlow,
  Path,
  SubjectModelType,
  FieldType,
  MediaForm,
} from '@/types'

interface SavePresetParams {
  name: string
  modelType: string
  formValues: any
  elements: any[]
  thumbnail?: Path
}

interface PresetContextType {
  isPresetMenuOpen: boolean
  kaiberPrompts: GetPromptsResponse
  userPresets: CompatiblePresetFlow[]
  kaiberPresets: CompatiblePresetFlow[]
  inputMedia: Media | null
  openPresetMenu: (media?: Media) => void
  closePresetMenu: () => void
  addPresetToCanvas: (preset: PresetFlow, position?: XYPosition) => void
  refreshPresets: () => Promise<void>
  savePreset: (params: SavePresetParams) => Promise<void>
  updatePreset: (preset: CompatiblePresetFlow) => Promise<void>
  togglePresetMenu: () => void
  deletePreset: (presetId: string) => Promise<void>
}

const PresetContext = createContext<PresetContextType | undefined>(undefined)

export const PRESET_FADE_OUT_MILLISECONDS = 300

const FIELD_REPLACEMENT_ORDERING: Record<MediaType, FieldType[]> = {
  [MediaType.Image]: [
    FieldType.ImageUpload,
    FieldType.FaceReferenceUpload,
    FieldType.ImageReferenceUpload,
    FieldType.ControlnetReferenceUpload,
    FieldType.StyleReferenceUpload,
  ],
  [MediaType.Video]: [FieldType.VideoUpload],
  [MediaType.Audio]: [],
}

export const PresetProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isPresetMenuOpen, setIsPresetMenuOpen] = useState(false)
  const [userPresets, setUserPresets] = useState<CompatiblePresetFlow[]>([])
  const [kaiberPresets, setKaiberPresets] = useState<CompatiblePresetFlow[]>([])
  const [kaiberPrompts, setKaiberPrompts] = useState<GetPromptsResponse | null>(
    null,
  )
  const [inputMedia, setInputMedia] = useState<Media | null>(null)
  const { addPreset } = useNodeUtility()

  const fetchSubject = async () => {
    const response = await fetchSubjectPrompts()
    const subjectPrompts: GetPromptsResponse = response.data
    setKaiberPrompts(subjectPrompts)
  }

  const fetchUserPresets = async () => {
    const response = await http.get(API_ENDPOINTS.GET_USER_PRESET_FLOWS)
    const compatiblePresets: CompatiblePresetFlow[] = response.data.map(
      (preset: PresetFlow) => ({
        ...preset,
        isCompatible: true,
      }),
    )
    setUserPresets(compatiblePresets)
  }

  const fetchKaiberPresets = async () => {
    const response = await http.get(API_ENDPOINTS.GET_KAIBER_PRESET_FLOWS)
    const compatiblePresets: CompatiblePresetFlow[] = response.data.map(
      (preset: PresetFlow) => ({
        ...preset,
        isCompatible: true,
      }),
    )
    setKaiberPresets(compatiblePresets)
  }

  const refreshPresets = useCallback(async () => {
    await Promise.all([
      fetchUserPresets(),
      fetchKaiberPresets(),
      fetchSubject(),
    ])
  }, [])

  useInitialLoad(refreshPresets)

  useEffect(() => {
    setKaiberPresets((prevPresets) =>
      prevPresets.map((preset) => ({
        ...preset,
        isCompatible: inputMedia
          ? preset.inputType?.includes(inputMedia.type)
          : true,
      })),
    )
    setUserPresets((prevPresets) =>
      prevPresets.map((preset) => {
        return {
          ...preset,
          isCompatible: inputMedia
            ? preset.inputType?.includes(inputMedia.type)
            : true,
        }
      }),
    )
  }, [inputMedia])

  const openPresetMenu = useCallback((media?: Media) => {
    setIsPresetMenuOpen(true)
    setInputMedia(media ?? null)
  }, [])

  const closePresetMenu = useCallback(() => {
    setTimeout(() => {
      setInputMedia(null)
    }, PRESET_FADE_OUT_MILLISECONDS)
    setIsPresetMenuOpen(false)
  }, [])

  const togglePresetMenu = useCallback(() => {
    isPresetMenuOpen ? closePresetMenu() : openPresetMenu()
  }, [closePresetMenu, isPresetMenuOpen, openPresetMenu])

  const addPresetToCanvas = useCallback(
    (preset: PresetFlow, position?: XYPosition) => {
      let updatedPreset = preset

      if (inputMedia) {
        const { flow } = preset
        const { formValues } = flow
        const updatedFormValues: Record<string, any> = {
          ...formValues,
          subject: inputMedia.subject || formValues.subject,
          style: inputMedia.style || formValues.style,
        }

        for (const field of FIELD_REPLACEMENT_ORDERING[inputMedia.type]) {
          if (field === FieldType.VideoUpload) {
            // VideoUpload does not support arrays of video yet
            updatedFormValues[field] = inputMedia.assetKey
          } else if (field in updatedFormValues) {
            updatedFormValues[field] = [
              inputMedia.assetKey,
              ...updatedFormValues[field].slice(1),
            ]
            // We only want to replace the first form element, as the others might have default values
            break
          }
        }

        updatedPreset = {
          ...preset,
          flow: {
            ...preset.flow,
            formValues: updatedFormValues as MediaForm,
          },
        }
      }

      const subjectModelType = SubjectModelType[updatedPreset.flow.modelType]
      const subjects = kaiberPrompts.subjects[subjectModelType] ?? []
      const subject =
        subjects[Math.floor(Math.random() * subjects.length)]?.subjectPrompt ||
        updatedPreset.flow.formValues.subject

      updatedPreset = {
        ...updatedPreset,
        flow: {
          ...updatedPreset.flow,
          formValues: {
            ...updatedPreset.flow.formValues,
            subject,
          },
        },
      }

      addPreset(updatedPreset, preset.name, position)
      closePresetMenu()
    },
    [addPreset, closePresetMenu, inputMedia, kaiberPrompts],
  )

  const savePreset = useCallback(
    async ({
      name,
      modelType,
      formValues,
      elements,
      thumbnail,
    }: SavePresetParams) => {
      try {
        const response = await http.post(API_ENDPOINTS.SAVE_PRESET_FLOW, {
          name: name || `${modelType} Preset`,
          flow: {
            modelType,
            formValues,
            elements,
          },
          thumbnail,
        })
        const savedPreset = response.data
        setUserPresets((prevPresets) => [
          ...prevPresets,
          {
            ...savedPreset,
            isCompatible: inputMedia
              ? savedPreset.inputType?.includes(inputMedia.type)
              : true,
          },
        ])
      } catch (error) {
        console.error('Error saving preset:', error)
        throw error
      }
    },
    [inputMedia],
  )

  const updatePreset = useCallback(
    async (preset: CompatiblePresetFlow) => {
      // Store the current state for potential rollback
      const previousPresets = userPresets

      // Optimistically update the state for faster UI responsiveness
      setUserPresets((prevPresets) =>
        prevPresets.map((p) =>
          p.presetId === preset.presetId
            ? {
                ...preset,
                isCompatible: inputMedia
                  ? preset.inputType?.includes(inputMedia.type)
                  : true,
              }
            : p,
        ),
      )

      try {
        const response = await http.put(
          `${API_ENDPOINTS.UPDATE_PRESET_FLOW}/${preset.presetId}`,
          preset,
        )
        const updatedPreset = response.data

        // If successful, update the state with the server response
        setUserPresets((prevPresets) =>
          prevPresets.map((p) =>
            p.presetId === updatedPreset.presetId
              ? {
                  ...updatedPreset,
                  isCompatible: inputMedia
                    ? updatedPreset.inputType?.includes(inputMedia.type)
                    : true,
                }
              : p,
          ),
        )
      } catch (error) {
        // If the update fails, roll back to the previous state
        console.error('Error updating preset:', error)
        setUserPresets(previousPresets)
        throw error
      }
    },
    [inputMedia, userPresets],
  )

  const deletePreset = useCallback(
    async (presetId: string) => {
      // Find the preset to be deleted
      const presetToDelete = userPresets.find(
        (preset) => preset.presetId === presetId,
      )

      if (!presetToDelete) {
        console.error('Preset not found:', presetId)
        return
      }
      try {
        // Optimistically remove the preset from the state
        setUserPresets((prevPresets) =>
          prevPresets.filter((preset) => preset.presetId !== presetId),
        )

        await http.delete(`${API_ENDPOINTS.DELETE_PRESET_FLOW}/${presetId}`)
        setUserPresets((prevPresets) =>
          prevPresets.filter((preset) => preset.presetId !== presetId),
        )
      } catch (error) {
        // If the deletion fails, reinsert the preset
        console.error('Error deleting preset:', error)
        setUserPresets((prevPresets) => [...prevPresets, presetToDelete])
        throw error
      }
    },
    [userPresets],
  )

  return (
    <PresetContext.Provider
      value={{
        isPresetMenuOpen,
        userPresets,
        kaiberPresets,
        kaiberPrompts,
        inputMedia,
        openPresetMenu,
        closePresetMenu,
        addPresetToCanvas,
        refreshPresets,
        savePreset,
        updatePreset,
        togglePresetMenu,
        deletePreset,
      }}
    >
      {children}
    </PresetContext.Provider>
  )
}

export const usePresetContext = () => {
  const context = useContext(PresetContext)
  if (context === undefined) {
    throw new Error('usePresetContext must be used within a PresetProvider')
  }
  return context
}

PresetContext.displayName = 'PresetContext'
