import { Node, useReactFlow } from '@xyflow/react'
import {
  createContext,
  useContext,
  useState,
  useCallback,
  MouseEvent,
  SetStateAction,
  Dispatch,
  useRef,
} from 'react'

import { FlowNodeData } from '../components/k2/Nodes/FlowNode/FlowNode'
import { FLOWS_CONFIG } from '../config'
import { useAnalytics } from '../hooks'
import {
  createCanvas as createCanvasApi,
  getCanvases as getCanvasesApi,
  updateTitle as updateTitleApi,
} from '../services/CanvasService'
import {
  AnalyticsEvent,
  ContainerState,
  BaseProviderProps,
  ModelType,
  ElementType,
  NodeType,
  Canvas,
} from '@/types'

type OnNodeDragStopCallback = (event: MouseEvent, node: Node) => void
type OnNodeIntersectionCallback = (
  registeredNode: Node,
  intersectingNode: Node,
) => void

type Canvases = {
  [key: string]: Canvas
}

const REACT_FLOW_CLASS = '.react-flow__renderer'

export const CanvasProvider = ({ children }: BaseProviderProps) => {
  const { updateNodeData, getNode } = useReactFlow()
  const { trackEvent } = useAnalytics()
  const [canvases, setCanvases] = useState<Canvases>({})
  const [showNav] = useState<boolean>(false)
  const [containerStates, setContainerStates] = useState<
    Record<string, ContainerState>
  >({})
  const [onNodeDragStopCallbacks, setOnNodeDragStopCallbacks] = useState<
    OnNodeDragStopCallback[]
  >([])
  const [onNodeIntersectionCallbacks, setOnNodeIntersectionCallbacks] =
    useState<Record<string, OnNodeIntersectionCallback>>({})

  const [preventScrolling, setPreventScrolling] = useState<boolean>(true)

  // having selected node ids in the canvas context for group actions (delete, duplicate, recreate)
  const [selectedNodeIds, setSelectedNodeIds] = useState<Set<string>>(
    new Set<string>([]),
  )

  const reactFlowWrapper = useRef<HTMLDivElement>(null)

  const getReactFlowElement = useCallback(() => {
    return reactFlowWrapper.current?.querySelector(REACT_FLOW_CLASS)
  }, [reactFlowWrapper])

  const addCanvasElement = (nodeId: string, newElement: ElementType) => {
    const node = getNode(nodeId)

    if (node.type !== NodeType.FlowNode) {
      throw new Error(
        `Node must be of type FlowNode to add canvas elements. Got ${node.type} instead.`,
      )
    }

    const modelType = (node.data as FlowNodeData).modelType

    const prevElements: ElementType[] = (node.data as FlowNodeData).elements
    updateNodeData(nodeId, {
      elements: [...prevElements, newElement],
    })

    trackEvent(AnalyticsEvent.ElementAdded, {
      nodeId,
      elementType: newElement,
      modelType,
    })
  }

  const initializeFlowElements = (nodeId: string, modelType: ModelType) => {
    const node = getNode(nodeId)

    if (node.type !== NodeType.FlowNode) {
      throw new Error(
        `Node must be of type FlowNode to initialize flow elements. Got ${node.type} instead.`,
      )
    }

    const flowConfig = FLOWS_CONFIG[modelType]
    updateNodeData(nodeId, {
      elements: flowConfig.requiredElements,
    })
  }

  const removeCanvasElement = (nodeId: string, elementIndex: number) => {
    const node = getNode(nodeId)

    if (node.type !== NodeType.FlowNode) {
      throw new Error(
        `Node must be of type FlowNode to remove canvas elements. Got ${node.type} instead.`,
      )
    }

    const prevElements: ElementType[] = (node.data as FlowNodeData).elements
    const newElements = prevElements.filter(
      (_, index) => index !== elementIndex,
    )
    updateNodeData(nodeId, {
      elements: newElements,
    })
  }

  const addContainerState = (
    containerId: string,
    initialState: ContainerState,
  ) => {
    setContainerStates((prevStates) => ({
      ...prevStates,
      [containerId]: initialState,
    }))
  }

  const updateContainerState = (
    containerId: string,
    updatedState: Partial<ContainerState>,
  ) => {
    setContainerStates((prevStates) => ({
      ...prevStates,
      [containerId]: {
        ...prevStates[containerId],
        ...updatedState,
      },
    }))
  }

  const removeContainerState = (containerId: string) => {
    setContainerStates((prevStates) => {
      const { [containerId]: _, ...restStates } = prevStates
      return restStates
    })
  }

  const registerOnNodeDragStop = (callback: OnNodeDragStopCallback) => {
    setOnNodeDragStopCallbacks((prevCallbacks) => [...prevCallbacks, callback])
  }

  const invokeOnNodeDragStopCallbacks = (event: MouseEvent, node: Node) => {
    onNodeDragStopCallbacks.forEach((callback) => callback(event, node))
  }

  const registerOnNodeIntersection = useCallback(
    (nodeId: string, callback: OnNodeIntersectionCallback) => {
      setOnNodeIntersectionCallbacks((prevCallbacks) => ({
        ...prevCallbacks,
        [nodeId]: callback,
      }))
    },
    [setOnNodeIntersectionCallbacks],
  )

  const invokeOnNodeIntersectionCallbacks = (
    registeredNode: Node,
    intersectingNode: Node,
  ) => {
    const callback = onNodeIntersectionCallbacks[intersectingNode.id]
    // note: the naming of arguments is reversed in the callback, since nodeId
    // here is the node that was just "dropped" onto the registering node
    // thus, nodeId is the intersecting node, and intersectingNodeId is the registering node

    if (callback) {
      callback(intersectingNode, registeredNode)
    }
  }

  const createCanvas = async (): Promise<Canvas | null> => {
    try {
      const res = await createCanvasApi()
      setCanvases((prev) => {
        return {
          ...prev,
          [res.id]: res,
        }
      })
      return res
    } catch (err: any) {
      if (err.response) {
        console.error(err.response.data.message)
      } else {
        console.error('Can not create the canvas', err)
      }
      return null
    }
  }

  const getCanvases = async (): Promise<Canvas[]> => {
    try {
      const res = await getCanvasesApi()
      const canvasesObj = res.reduce((prev, canvas) => {
        return { ...prev, [canvas.id]: canvas }
      }, {})
      setCanvases(canvasesObj)
      return res
    } catch (error: any) {
      if (error.response) {
        console.error(error.response.data.message)
      } else {
        console.error('Can not load the canvas lists', error)
      }
      return []
    }
  }

  const updateTitle = async (id: string, title: string): Promise<void> => {
    try {
      const res = await updateTitleApi(id, title)
      setCanvases((prev) => {
        return {
          ...prev,
          [res.id]: {
            id: res.id,
            title: res.title,
          },
        }
      })
      trackEvent(AnalyticsEvent.CanvasUpdated, {
        updatedFields: { title },
      })
    } catch (error) {
      console.error(`Can not update title of canvas ${id}`, error)
    }
  }

  return (
    <CanvasContext.Provider
      value={{
        preventScrolling,
        setPreventScrolling,
        selectedNodeIds,
        setSelectedNodeIds,
        addCanvasElement,
        addContainerState,
        containerStates,
        initializeFlowElements,
        invokeOnNodeDragStopCallbacks,
        onNodeDragStopCallbacks,
        registerOnNodeDragStop,
        removeCanvasElement,
        removeContainerState,
        showNav,
        updateContainerState,
        onNodeIntersectionCallbacks,
        registerOnNodeIntersection,
        invokeOnNodeIntersectionCallbacks,
        canvases,
        createCanvas,
        getCanvases,
        updateTitle,
        reactFlowWrapper,
        getReactFlowElement,
      }}
    >
      <div ref={reactFlowWrapper}>{children}</div>
    </CanvasContext.Provider>
  )
}

export const CanvasContext = createContext<{
  preventScrolling: boolean
  setPreventScrolling: Dispatch<SetStateAction<boolean>>
  selectedNodeIds: Set<string>
  setSelectedNodeIds: Dispatch<SetStateAction<Set<string>>>
  addCanvasElement: (nodeId: string, newElement: ElementType) => void
  addContainerState: (containerId: string, initialState: ContainerState) => void
  containerStates: Record<string, ContainerState>
  initializeFlowElements: (nodeId: string, modelType: ModelType) => void
  invokeOnNodeDragStopCallbacks: (event: MouseEvent, node: Node) => void
  onNodeDragStopCallbacks: OnNodeDragStopCallback[]
  registerOnNodeDragStop: (callback: OnNodeDragStopCallback) => void
  removeCanvasElement: (nodeId: string, ElementIndex: number) => void
  removeContainerState: (containerId: string) => void
  showNav: boolean
  updateContainerState: (
    containerId: string,
    updatedState: Partial<ContainerState>,
  ) => void
  onNodeIntersectionCallbacks: Record<string, OnNodeIntersectionCallback>
  registerOnNodeIntersection: (
    nodeId: string,
    callback: OnNodeIntersectionCallback,
  ) => void
  invokeOnNodeIntersectionCallbacks: (
    selfNode: Node,
    intersectingNode: Node,
  ) => void
  canvases: Canvases
  createCanvas: () => Promise<Canvas>
  getCanvases: () => Promise<Canvas[]>
  updateTitle: (id: string, title: string) => Promise<void>
  reactFlowWrapper: React.RefObject<HTMLDivElement>
  getReactFlowElement: () => Element | null
}>({
  preventScrolling: true,
  setPreventScrolling: () => {},
  selectedNodeIds: new Set<string>([]),
  setSelectedNodeIds: () => {},
  addCanvasElement: () => {},
  addContainerState: () => {},
  containerStates: {},
  initializeFlowElements: () => {},
  invokeOnNodeDragStopCallbacks: () => {},
  onNodeDragStopCallbacks: [],
  registerOnNodeDragStop: () => {},
  removeCanvasElement: () => {},
  removeContainerState: () => {},
  showNav: false,
  updateContainerState: () => {},
  onNodeIntersectionCallbacks: {},
  registerOnNodeIntersection: () => {},
  invokeOnNodeIntersectionCallbacks: () => {},
  canvases: {},
  createCanvas: async () => {
    return null
  },
  getCanvases: async () => {
    return []
  },
  updateTitle: async () => {},
  reactFlowWrapper: { current: null },
  getReactFlowElement: () => null,
})

export const useCanvasContext = () => useContext(CanvasContext)

CanvasContext.displayName = 'CanvasContext'
