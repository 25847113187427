import { AxiosProgressEvent } from 'axios'
import { debounce } from 'lodash'
import React, { useMemo, useRef, useState, useId } from 'react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'react-toastify'

import { getSignedUrl, saveMedia } from '../../../../api'
import { LABELS } from '../../../../constants'
import { useThemeContext } from '../../../../context/themeContext'
import { useInitialLoad } from '../../../../hooks'
import { useAnalytics } from '../../../../hooks'
import { ExclamationTriangleIcon } from '../../../../images/icons/ExclamationTriangleIcon'
import { FileUploadIcon } from '../../../../images/icons/FileUploadIcon'
import { LoadingIcon } from '../../../../images/icons/LoadingIcon'
import { TrashOutlineIcon } from '../../../../images/icons/TrashOutlineIcon'
import { mediaStore, myLibraryStore } from '../../../../stores'
import { cn } from '../../../../utils'
import { insertItemAndTrimArray } from '../../../../utils/arrayUtils'
import {
  useDndHandlers,
  findNearestDroppableElement,
  getDroppableIndex,
} from '../../../../utils/dndUtils'
import { getAssetKeyFromMedia } from '../../../../utils/mediaUtils'
import { IconButton } from '../../../Button'
import { Slider } from '../../Fields'
import { OverlayBackground } from '../../OverlayBackground'
import { CanvasBaseElement } from '../CanvasBaseElement'
import { CollapsibleFields } from '../CollapsibleFields'
import {
  ElementType,
  FieldType,
  FileWithSource,
  Image,
  ImageForm,
  TagNamespace,
  AnalyticsEvent,
  MediaUploadSource,
} from '@/types'

interface ImageUploadProps {
  formField?: FieldType
  label?: string
  maxFiles?: number
  allowWeights?: boolean
}

type TImageUploadDefaultOptions =
  | FieldType.ImageReferenceUpload
  | FieldType.StyleReferenceUpload
  | FieldType.CompositionReferenceUpload
  | FieldType.ControlnetReferenceUpload
  | FieldType.FaceReferenceUpload
  | FieldType.PulidFaceReferenceUpload

const DEFAULT_WEIGHT = 0.8

const IMAGE_UPLOAD_WEIGHTS: Record<TImageUploadDefaultOptions, number> = {
  [FieldType.ImageReferenceUpload]: 0.5, // based on testing, this value won't overwhelm text prompting: https://kaiberteam.atlassian.net/browse/RES-108
  [FieldType.StyleReferenceUpload]: 0.8,
  [FieldType.CompositionReferenceUpload]: 0.8,
  [FieldType.ControlnetReferenceUpload]: 0.8,
  [FieldType.FaceReferenceUpload]: 0.8,
  [FieldType.PulidFaceReferenceUpload]: 0.8,
}

const UPLOAD_ELEMENT_TYPE = 'UploadElement'

export const ImageUpload = ({
  formField = FieldType.ImageUpload,
  label = LABELS.REFERENCE_IMAGE,
  maxFiles = 1,
  allowWeights = false,
}: ImageUploadProps) => {
  const { colors } = useThemeContext()
  const { register, setValue, formState, getValues, watch } = useFormContext()
  const { trackEvent } = useAnalytics()
  const [uploadedFiles, setUploadedFiles] = useState<FileWithSource[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const weightsField = `${formField}Weights` as keyof ImageForm
  const weights: number[] = watch(weightsField) || []
  const id = useId()
  const abortControllerRef = useRef<AbortController | null>(null)

  const [isDroppable, setIsDroppable] = useState(false)
  const [isError, setIsError] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)

  useInitialLoad(async () => {
    const { [formField]: storedAssetKeys } = getValues()

    // FYI- this is probably not the most efficient way to load image urls
    //      we should think about a way to do this across all canvas elements
    //      based on CanvasState, and have it as a parallel call on canvas load
    if (
      storedAssetKeys &&
      typeof storedAssetKeys === 'object' &&
      Array.isArray(storedAssetKeys)
    ) {
      const fetchThumbnailUrls = async () => {
        const urls: string[] = await Promise.all(
          storedAssetKeys.map((assetKey: string) => getSignedUrl(assetKey)),
        )

        const validUrls = urls.filter((url) => url !== '')
        const initialFiles = validUrls.map((url, index) => {
          const file = new File([], storedAssetKeys[index] || '')
          return { file, source: url }
        })
        setUploadedFiles(initialFiles)
      }

      fetchThumbnailUrls()
    }
  })

  /**
   * Remove uploaded image.
   * This removes the image's assetKey from the form and CanvasState.
   * Also removes the thumbnail, and weight.
   */
  const handleRemoveImage = (index: number) => {
    // Update asset keys in form
    const currentAssetKeys = getValues(formField) as string[]
    const updatedAssetKeys = currentAssetKeys.filter((_, i) => i !== index)
    setValue(formField, updatedAssetKeys)

    // Remove uploaded file
    setUploadedFiles(uploadedFiles.filter((_, i) => i !== index))

    // Update weights
    const updatedWeights = weights.filter((_, i) => i !== index)
    setValue(weightsField, updatedWeights)
  }

  /**
   * Handle file upload.
   */
  const handleFileDrop = async (
    filesWithSource: FileWithSource[],
    event?: React.DragEvent<HTMLDivElement>,
  ) => {
    // One file upload at a time
    if (!filesWithSource.length) return
    if (filesWithSource.length > 1) {
      toast.error(`Please upload one file at a time.`)
      return
    }

    const { file, source } = filesWithSource[0]
    let media: Image = { ...mediaStore.findMediaBySource(source) }
    if (media) {
      media.assetKey = await getAssetKeyFromMedia(media)
    }

    // if we can't find the asset key, we need to reupload
    if (!media?.assetKey) {
      try {
        setIsUploading(true)
        setIsError(false)
        abortControllerRef.current = new AbortController()

        media = (await saveMedia(
          file,
          [
            { ns: TagNamespace.MediaType, name: 'Image' },
            { ns: TagNamespace.Uploaded, name: 'Yes' },
          ],
          null,
          (progressEvent: AxiosProgressEvent) => {
            const debouncedSetProgress = debounce((progress: number) => {
              setUploadProgress(progress)
            }, 100)

            const progress = Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100,
            )

            debouncedSetProgress(progress)
          },
          abortControllerRef.current.signal,
        )) as Image
        mediaStore.setMedia(media)
        myLibraryStore.prependMediaId(media.mediaId)
        trackEvent(AnalyticsEvent.MediaUploaded, {
          mediaId: media.mediaId,
          mediaType: media.type,
          fileSize: file.size,
          fileType: file.type,
          uploadSource: MediaUploadSource.ImageUpload,
        })
      } catch (error: any) {
        setIsUploading(false)
        setUploadProgress(0)
        // if the upload was cancelled, don't show an error
        if (error.name === 'CanceledError') {
          return
        }
        setIsError(true)
        console.error('Error uploading media:', error)
        toast.error('Failed to upload image. Please try again.')

        return
      } finally {
        setUploadProgress(0)
        setIsUploading(false)
        abortControllerRef.current = null
      }
    }

    if (!media) {
      toast.error('Failed to process image. Please try again.')

      setIsUploading(false)

      return
    }

    setIsUploading(false)

    const newUploadedFile: FileWithSource = { file, source: media.source }
    const currentAssetKeys = getValues(formField) as string[]
    const newAssetKey = media.assetKey

    let insertIndex = uploadedFiles.length // Default to appending

    if (event) {
      const nearestDroppable = findNearestDroppableElement(
        {
          x: event.clientX,
          y: event.clientY,
        },
        '[data-upload-droppable]',
      )

      if (nearestDroppable) {
        const elementType = nearestDroppable.getAttribute('data-element-type')

        switch (elementType) {
          case UPLOAD_ELEMENT_TYPE:
            insertIndex = getDroppableIndex(nearestDroppable)
            break
          default:
            break
        }
      }
    }

    // Update uploaded files
    const newUploadedFiles = insertItemAndTrimArray(
      uploadedFiles,
      insertIndex,
      newUploadedFile,
      maxFiles,
    )
    setUploadedFiles(newUploadedFiles)

    // Update asset keys
    const newAssetKeys = insertItemAndTrimArray(
      currentAssetKeys,
      insertIndex,
      newAssetKey,
      maxFiles,
    )
    setValue(formField, newAssetKeys, {
      shouldValidate: true,
      shouldDirty: true,
    })

    // Update weights
    const newWeights = insertItemAndTrimArray(
      weights,
      insertIndex,
      IMAGE_UPLOAD_WEIGHTS[formField as TImageUploadDefaultOptions] ??
        DEFAULT_WEIGHT,
      maxFiles,
    )
    setValue(weightsField, newWeights, {
      shouldValidate: true,
      shouldDirty: true,
    })

    if (
      formField === FieldType.ControlnetReferenceUpload ||
      formField === FieldType.ImageUpload
    ) {
      // only controlnet reference will override the height and width of this
      setValue('width', media.width)
      setValue('height', media.height)
    }
  }

  const handleCancelUpload = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
  }

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const filesWithSource = Array.from(event.target.files).map((file) => ({
      file,
      source: URL.createObjectURL(file),
    }))
    if (filesWithSource) {
      await handleFileDrop(Array.from(filesWithSource))
    }

    // need to reset the file input after file upload
    // because if delete uploaded file and upload same file again, handleInputChange is not working
    inputRef.current.value = ''
  }

  const handleWeightChange = (index: number, newWeight: number) => {
    const updatedWeights = [...weights]
    updatedWeights[index] = newWeight
    setValue(weightsField, updatedWeights)
  }

  const errorMessage = useMemo(() => {
    if (!formState.isValid && formState.errors) {
      return (formState.errors[formField]?.message ?? '') as string
    }
    return undefined
  }, [formField, formState])

  const {
    handleDrop,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    handleDragEnd,
  } = useDndHandlers({ handleFileDrop })

  return (
    <CanvasBaseElement
      label={label}
      errorMessage={errorMessage}
      className='p-0'
    >
      <div
        data-droppable={true}
        data-element-type={ElementType.ImageUpload}
        onDrop={(event: React.DragEvent<HTMLDivElement>) => {
          setIsDroppable(false)
          handleDrop(event)
        }}
        onDragOver={(event: React.DragEvent<HTMLDivElement>) => {
          setIsDroppable(true)
          handleDragOver(event)
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={(event: React.DragEvent<HTMLDivElement>) => {
          setIsDroppable(false)
          handleDragLeave(event)
        }}
        onDragEnd={handleDragEnd}
        onClick={() => inputRef.current?.click()}
      >
        <div>
          {uploadedFiles.length > 0 && (
            <div
              className={
                'flex flex-wrap gap-2 h-max rounded-2xl overflow-hidden'
              }
            >
              {uploadedFiles.map((uploadedFile, index) => (
                <div
                  key={index}
                  className='relative w-full h-max group'
                  data-upload-droppable={true}
                  data-index={index}
                  data-element-type={UPLOAD_ELEMENT_TYPE}
                >
                  <img
                    src={uploadedFile.source}
                    alt='Thumbnail'
                    className='w-full object-cover z-0 rounded-2xl'
                    draggable={false}
                  />
                  <OverlayBackground>
                    <div
                      className='absolute w-full truncate top-3 left-0 px-3 text-[10px] text-white'
                      title={uploadedFile.file.name}
                    >
                      {uploadedFile.file.name}
                    </div>
                    <IconButton
                      icon={TrashOutlineIcon}
                      className='absolute bottom-3 right-3 text-white'
                      size={20}
                      title='remove image'
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        e.stopPropagation()
                        handleRemoveImage(index)
                      }}
                    />
                  </OverlayBackground>
                </div>
              ))}
            </div>
          )}
          {uploadedFiles.length < maxFiles && (
            <div
              className={cn(
                `p-2 mt-2 border border-gray-500 rounded-2xl ${colors.elevation.surface.sunken} h-full flex flex-col items-center justify-center gap-2`,
                {
                  'border-green-500 border-dashed': isDroppable && !isUploading,
                },
              )}
              data-upload-droppable={true}
            >
              {isUploading ? (
                <>
                  <LoadingIcon width='24px' height='24px' />
                  <div className={`${colors.text.default} text-k2-sm`}>
                    Uploading media...{uploadProgress}%
                  </div>
                  <button
                    className='py-1 px-4 rounded-full cursor-pointer border border-kaiber-red text-kaiber-red text-k2-xs border-full'
                    onClick={(e) => handleCancelUpload(e)}
                  >
                    Cancel Upload
                  </button>
                </>
              ) : isError ? (
                <>
                  <ExclamationTriangleIcon
                    width='24px'
                    height='24px'
                    className='text-kaiber-red'
                  />
                  <div className={`${colors.text.default} text-k2-sm`}>
                    Error uploading media
                  </div>
                  <label
                    htmlFor={id}
                    className={`py-1 px-4 rounded-full cursor-pointer border ${colors.border.brand} ${colors.text.default} text-k2-xs border-full`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Choose Another File
                  </label>
                </>
              ) : (
                <>
                  <FileUploadIcon width='24px' height='24px' />
                  <div className={`${colors.text.default} text-k2-sm`}>
                    Drop image here
                  </div>
                  <div className={`${colors.text.default} text-k2-xs`}>
                    Up to 20MB
                  </div>
                  <label
                    htmlFor={id}
                    className={`py-1 px-4 rounded-full cursor-pointer border ${colors.border.brand} ${colors.text.brand} text-k2-sm border-full`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Choose File
                  </label>
                </>
              )}
            </div>
          )}

          {/* hidden field for file upload */}
          <input
            ref={inputRef}
            id={id}
            type='file'
            accept='image/*'
            className='hidden'
            onChange={handleInputChange}
            onClick={(e) => e.stopPropagation()}
            multiple={maxFiles > 1}
          />

          {/* hidden field for assetKey */}
          <input
            className='hidden'
            {...register(formField, {
              validate: (assetKey: string) =>
                !!assetKey || 'Need to upload an image first.',
            })}
          />

          {/* hidden field for weights */}
          <input
            id={weightsField}
            className='hidden'
            {...register(weightsField)}
          />
        </div>
      </div>
      {allowWeights && uploadedFiles.length > 0 && (
        <CollapsibleFields>
          <div className='space-y-2'>
            <span className='text-xs px-2'>Intensity</span>
            {uploadedFiles.map((uploadedFile, index) => (
              <div key={index} className='flex items-center space-x-2 px-2'>
                {/* only attempt to render if file name exists */}
                {uploadedFile.file?.name && (
                  <span
                    className='w-1/3 truncate text-[10px]'
                    title={uploadedFile.file.name}
                  >
                    {uploadedFile.file.name}
                  </span>
                )}
                <div className='flex-1'>
                  <Slider
                    min={0}
                    max={1}
                    step={0.01}
                    title='Weight'
                    value={weights[index]}
                    onChange={(e) =>
                      handleWeightChange(index, parseFloat(e.target.value))
                    }
                    onMouseDown={(e) => e.stopPropagation()}
                    onTouchStart={(e) => e.stopPropagation()}
                  />
                </div>
              </div>
            ))}
          </div>
        </CollapsibleFields>
      )}
    </CanvasBaseElement>
  )
}
